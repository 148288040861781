import React, { Component } from 'react';
// import { Link } from '@reach/router'
import { PageTransition } from 'anims.js';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';

import { AppContext } from 'context.js';
import { get } from 'utils/requests.js'
// import { Leaderboard } from 'pages/games/shootey-guy/ShooteyGuyLeaderboards.js';

import mmo_logo from './img/mmo_logo.png';
import incremental_logo from './img/incremental_logo.png';
import fighty_logo from './img/fighty_logo.gif';
import shootey_guy_demo_1 from './img/shootey_guy_demo_1.gif';

import './Games.scss';


export class Games extends Component {
  static contextType = AppContext;
  
  constructor(props) {
    super(props);
    this.state = {
      pollInterval: null,
      mmoServerStatus: 'unknown',
      ShooteyGuyServerStatus: 'unknown',
      isMobile: isMobile,
    }
  }
  
  componentDidMount() {
    this.getData();
    var pollInterval = setInterval(() => {
      this.getData();
    }, 1000);
    this.setState({pollInterval: pollInterval});
  }
  
  componentWillUnmount() {
    clearInterval(this.state.pollInterval);
  }
  
  getData = () => {
    get('/api/mmo-server-status/')
    .then(resp => {
      this.setState({mmoServerStatus: resp.game_running ? 'up' : 'down'});
    })
    .catch(err => {
      this.setState({mmoServerStatus: 'unknown'});
    })
    
    get('/api/shootey_guy-server-status/')
    .then(resp => {
      this.setState({ShooteyGuyServerStatus: resp.processor_running && resp.socket_running ? 'up' : 'down'});
    })
    .catch(err => {
      this.setState({ShooteyGuyServerStatus: 'unknown'});
    })
  }
  
  playClick = (slug) => {
    if (this.state.isMobile) return;
    setTimeout(() => {
      window.location = '/games/'+slug;
    }, 200)
  }
  
  render() {
    const shooteyGuyServerUp = this.state.ShooteyGuyServerStatus === 'up';
    const shooteyGuyPlayable = this.context.authed && shooteyGuyServerUp;
    
    // var shooteyGuyDesc = <>
    //     Shootey Guy is an online multiplayer, bullet hell, rogue-lite type game. Kill baddies, level up, acquire sweet sweet loot, and DONT DIE!<br/><br/>Leaderboards now included so you can show all your friends how cool you are.
    //     </>
    
    var shooteyGuyDesc = <>
      Shootey Guy is an online multiplayer, bullet hell, rogue-lite type game. Kill baddies, level up, acquire sweet sweet loot, and DONT DIE!
    </>
    
    // let leaderboardOutput = <>
    // <div className='small-leaderboard'>
    //     <Leaderboard
    //       title='Top Players'
    //       count={5}
    //       leaderboardKey='total_exp_gained'
    //     />
    // </div>
    // <Link to='/games/shootey_guy/leaderboards'>
    //   <motion.button className='default-btn' whileHover={{ scale: 1.04 }} whileTap={{ scale: 0.96 }}>
    //       View all leaderboards
    //   </motion.button>
    // </Link>
    // </>
    
    let leaderboardOutput = null;
    
    return (
      <PageTransition pageKey='Games'>
        <div className='games-container'>
          {this.state.isMobile ? (
            <div className='unsupported-device'>
              <div className='unsupported-device-title'>UNSUPPORTED DEVICE</div>
              <div className='unsupported-device-body'>These games are not playable on mobile devices</div>
            </div>
          ) : null}
          
          <div className='big-fade-title'>FEATURED</div>
          
          <div className='current-project-content'>
            <div className='left-pic'>
              <img src={shootey_guy_demo_1} alt='Shootey Guy'/>
            </div>
            <div className='right-content'>
              <div className='title'>Shootey Guy</div>
              <div className='desc'>{shooteyGuyDesc}</div>
              
              <motion.button className={'big-play-btn' + (shooteyGuyPlayable ? '' : ' disabled')} whileHover={{ scale: shooteyGuyPlayable ? 1.04 : 1 }} whileTap={{ scale: shooteyGuyPlayable ? 0.96 : 1 }} onClick={() => {
                if (shooteyGuyPlayable) {
                  this.playClick('shootey_guy')
                }
              }}>Play</motion.button>
              
              {this.context.authed ? null : (
                <div className='bad-text'>You must be logged in to play.</div>
              )}
              {shooteyGuyServerUp ? null : (
                <div className='bad-text'>The game server is not currently running, sorry :(</div>
              )}
            </div>
          </div>
          
          {leaderboardOutput}
          
          <div className='big-fade-title top-pad'>OLD STUFF</div>
          <div className='page-sub-title'>(Disclaimer: these are unfinished/abanadoned/unbalanced/broken. Please dont be angry)</div>
          
          <div className='game-list'>
            <motion.div className='game-block' onClick={e => this.playClick('fighty')} whileTap={{scale: 0.95}}>
              <div className='title'>Adventurey Guy</div>
              <div className='image'><img src={fighty_logo} alt='Fighty'/></div>
              <div className='description'>
                Single player RPG with resource harvesting, crafting and NPCs... what more could you want?
                <br/><br/><br/>Login required: <strong className='good-text'>no</strong>
              </div>
            </motion.div>
            
            <motion.div className='game-block' onClick={e => this.playClick('incremental')} whileTap={{scale: 0.95}}>
              <div className='title'>Incremental</div>
              <div className='image'><img src={incremental_logo} alt='Incremental'/></div>
              <div className='description'>
                An island/structure grid based incremental game<br/>
                <br/><br/><br/>Login required: <strong className='good-text'>no</strong>
              </div>
            </motion.div>
            
            <motion.div className='game-block' onClick={e => this.playClick('mmo')} whileTap={{scale: 0.95}}>
              <div className='title'>MMO</div>
              <div className='image'><img src={mmo_logo} alt='MMORPG'/></div>
              <div className='description'>
                A typical mmo style game with stats, skills, abilities, loot, gear, mobs, guilds, chat etc
                <br/><br/>Login required: <strong className={this.context.authed ? 'good-text' : 'bad-text'}>yes</strong>
                <br/>Server status: <span className={'status-'+this.state.mmoServerStatus}><strong>{this.state.mmoServerStatus}</strong></span>
              </div>
            </motion.div>
          </div>
          
        </div>
      </PageTransition>
    );
  }
}
