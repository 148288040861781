import React, { Component } from 'react';

import { AppContext } from 'context.js'
import { PageTransition } from 'anims.js';


export class Home extends Component {
  static contextType = AppContext;
  
  constructor(props) {
    super(props);
    this.state = {}
  }
  
  render() {
    let welcomeMessage = 'Welcome!';
    
    if (this.context.authed) {
      welcomeMessage = 'Sup, ' + this.context.username + '  :)';
      
      if (this.context.staff) {
        welcomeMessage += '  [staff]';
      }
    }
    
    return (
      <PageTransition pageKey='Home'>
        <div className='default-page-container'>
          <div className='page-title'>{welcomeMessage}</div>
          <div className='page-content'>
            {this.context.authed ? (
              <>
                <br/><br/>
                This is the home page.
                <br/><br/>
                Cool things will happen here! ...eventually...
              </>
            ) : null}
          </div>
        </div>
      </PageTransition>
    );
  }
}
