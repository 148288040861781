import React, { Component } from 'react';
import { navigate } from '@reach/router';
import { motion } from 'framer-motion';

import { AppContext } from 'context.js';
import { PageTransition } from 'anims.js';
import { get } from 'utils/requests.js';

import './UserSettingsMain.scss';


export class UserSettingsMain extends Component {
  static contextType = AppContext;
  
  constructor(props) {
    super(props);
    
    this.state = {
      latestSettings: null,
    }
  }
  
  componentDidMount() {
    this.getUserSettings();
  }
  
  doLogout = (e) => {
    e.preventDefault();
    get('/api/users/logout/')
    .then(resp => {
      this.context.logout();
      navigate('/');
    })
    .catch(err => {
      console.log('logout error: ' + err);
    })
  }
  
  getUserSettings = () => {
    get('/api/users/settings/')
    .then(resp => {
      // console.log('resp', resp);
      this.setState({latestSettings: resp});
    })
    .catch(err => {
      this.setState({latestSettings: null});
    })
  }
  
  render() {
    let out = null;
    let settings = this.state.latestSettings;
    
    if (settings) {
      let joinDate = new Date(settings.user.date_joined).toLocaleString();
      let passwordDate = new Date(settings.user.password_last_changed).toLocaleString();
      let passwordAge = settings.user.password_age;
      
      out = <>
        <div className='section'>
          <div className='title'>General</div>
          <div className='field'>
            <div>Username</div>
            <div>{settings.user.username}</div>
          </div>
          <div className='field'>
            <div>Date joined</div>
            <div>{joinDate}</div>
          </div>
        </div>
        
        <div className='section'>
          <div className='title'>Email Address</div>
          <div className='field'>
            <div>Email</div>
            <div className={settings.user.email_verified ? '' : 'red'}>{settings.user.email_verified ? settings.user.email : '[Not set]'}</div>
          </div>
          {settings.user.email_verified ? null : 
            <div className='field'>
              <div className='small-text'>You have not linked an email to your account yet. Some functionality of the site will be unavailable until you do so. Notably, you will be unable to recover your account if you forget your credentials.</div>
            </div>
          }
          <div className='field'>
            <motion.div className='default-btn' whileHover={{scale: 1.05}} whileTap={{scale: 0.95}} onClick={() => {
              navigate('/settings/change-email');
            }}>Change email</motion.div>
          </div>
        </div>
        
        <div className='section'>
          <div className='title'>Password</div>
          <div className='field'>
            <div>Last updated</div>
            <div>{passwordDate}</div>
          </div>
          <div className='field'>
            <div>Password age</div>
            <div>{passwordAge}</div>
          </div>
          <div className='field'>
            <motion.div className='default-btn' whileHover={{scale: 1.05}} whileTap={{scale: 0.95}} onClick={() => {
              navigate('/settings/change-password')
            }}>Change password</motion.div>
          </div>
        </div>
        
        <motion.div className='default-btn logout-btn' whileHover={{scale: 1.05}} whileTap={{scale: 0.95}} onClick={(e) => {
          this.doLogout(e)
        }}>Logout</motion.div>
      </>
    }
    else {
      out = <div>Loading...</div>
    }
    
    return (
      <PageTransition pageKey='userSettingsPage'>
        <div className='default-page-container user-settings'>
          <div className='page-title'>Your settings, my liege</div>
          <div className='page-content'>{out}</div>
        </div>
      </PageTransition>
    );
  }
}
