import Cookies from 'js-cookie';


const getHeaders = (includeCsrf=true) => {
  let headers = {'Content-Type': 'application/json'}
  let csrf = Cookies.get('csrftoken')
  if (csrf && includeCsrf) {
    headers['X-CSRFToken'] = csrf
  }
  return headers
}

export function get(url) {
  let options = {
    method: 'GET',
    headers: getHeaders(false),
  }
  return fetch(url, options).then(resp => {
    return resp.json().then(json => {
      if (resp.ok) return json
      return Promise.reject(resp)
    })
  }).catch(resp => {
    // console.log('GET error:', resp);
    return Promise.reject(resp)
  })
}

export function post(url, data) {
  let options = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  }
  return fetch(url, options).then(resp => {
    return resp.json().then(json => {
      if (resp.ok) return json
      return Promise.reject(json)
    })
  }).catch(err => {
    // console.log('POST error:', err);
    return Promise.reject(err)
  })
}
