import React, { Component } from 'react';
import Countdown from 'react-countdown';
import { motion } from 'framer-motion';

import { AppContext } from 'context.js';
import { get } from 'utils/requests.js'
import { PageTransition } from 'anims.js';

import './ShooteyGuyLeaderboards.scss';


export class ShooteyGuyLeaderboards extends Component {
  static contextType = AppContext;
  
  constructor(props) {
    super(props);
    this.state = {
      activeLeaderboard: 'total_exp_gained',
    }
    this.variants = {
      hidden: {
        opacity: 0,
        transitionEnd: {
          display: 'none',
        },
      },
      visible: {
        opacity: 1,
        display: 'block',
      },
    }
  }
  
  componentDidMount() {
    
  }
  
  componentWillUnmount() {
    
  }
  
  render() {
    return (
      <PageTransition pageKey='ShooteyGuyLeaderboards'>
        <div className='leaderboards-page'>
          <div className='title'>Shootey Guy Leaderboards</div>
          
          <div className='toggle-buttons'>
            <motion.button className='default-btn' whileHover={{ scale: 1.04 }} whileTap={{ scale: 0.96 }} onClick={() => {
              this.setState({activeLeaderboard: 'total_exp_gained'});
            }}>Total EXP</motion.button>
            
            <motion.button className='default-btn' whileHover={{ scale: 1.04 }} whileTap={{ scale: 0.96 }} onClick={() => {
              this.setState({activeLeaderboard: 'boss_kills'});
            }}>Boss Kills</motion.button>
          </div>
            
          <div className='all-leaderboards'>
            <motion.div className='leaderboard-section' variants={this.variants} initial='hidden' animate={this.state.activeLeaderboard === 'total_exp_gained' ? 'visible' : 'hidden'}>
              <Leaderboard
                title='Total EXP'
                count={20}
                leaderboardKey='total_exp_gained'
              />
            </motion.div>
            <motion.div className='leaderboard-section' variants={this.variants} initial='hidden' animate={this.state.activeLeaderboard === 'boss_kills' ? 'visible' : 'hidden'}>
              <Leaderboard
                title='Boss Kills'
                count={20}
                leaderboardKey='boss_kills'
              />
            </motion.div>
          </div>
        </div>
      </PageTransition>
    );
  }
}
  
  
export class Leaderboard extends Component {
  static contextType = AppContext;
  
  constructor(props) {
    super(props);
    
    this.title = props.title;
    this.count = props.count;
    this.leaderboardKey = props.leaderboardKey;
    
    this.state = {
      nextRefreshTS: null,
      respLabels: null,
      respEntries: null,
    }
  }
  
  componentDidMount() {
    this.getData();
  }
  
  componentWillUnmount() {
    
  }
  
  getData = () => {
    get(`/api/shootey_guy-leaderboard/?key=${this.leaderboardKey}&count=${this.count}`)
    .then(resp => {
      this.setState({
        errorLoading: false,
        respLabels: resp.labels || [],
        respEntries: resp.entries || [],
        nextRefreshTS: Date.now() + 30000,
      });
    })
    .catch(err => {
      this.setState({
        errorLoading: true,
        nextRefreshTS: Date.now() + 30000,
      });
    })
  }
  
  render() {
    let tableOutput = null;
    
    if (this.state.errorLoading) {
      tableOutput = <div className='table-loading bad-text'>Error loading leaderboards</div>
    }
    else {
      if (this.state.respLabels === null && this.state.respEntries === null) {
        tableOutput = <div className='table-loading warning-text'>Loading leaderboards...</div>
      }
      else if (!this.state.respLabels.length || !this.state.respEntries.length) {
        tableOutput = <div className='table-loading'>Nothing to display</div>
      }
      else {
        let tableHeader = <div className='table-header'>
          {this.state.respLabels.map((label) => 
            <div key={'header-'+label} className='header-col'>{label}</div>
          )}
        </div>
        
        let tableRows = this.state.respEntries.map((entry,i) => (
          <div key={'table-row-'+i} className={'table-row rank-'+(i+1)+'-row'}>
            {entry.map((value,j) => 
              <div key={'table-row-'+i+'-value-'+j} className='col'>{value}</div>
            )}
          </div>
        ))
        
        tableOutput = <div className='table'>
          {tableHeader}
          {tableRows}
        </div>
      }
    }
          
    let refreshCountdown = null;
    if (this.state.nextRefreshTS !== null) {
      refreshCountdown = <div className='refresh-countdown'>
        <div>{this.state.errorLoading ? 'Retrying in' : 'Refreshing in'}</div>
        <Countdown
          date={this.state.nextRefreshTS}
          key={'cd-'+this.state.nextRefreshTS}
          onComplete={() => {
            this.getData();
          }}
          renderer={props => <div>{props.seconds}s</div>}
        />
      </div>
    }
          
    return (
      <div className='leaderboard-container'>
        <div className={'leaderboard ' + this.leaderboardKey}>
          {this.title ? <div className='title'><span>{this.title}</span></div> : null}
          {tableOutput}
        </div>
        {refreshCountdown}
      </div>
    );
  }
}
