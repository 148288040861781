import React, { Component } from 'react';
import { motion } from 'framer-motion';
import { navigate } from '@reach/router';

import { AppContext } from 'context.js';
import { Form } from 'components/Form.js';
import { Text } from 'components/fields/Text.js'
import { PageTransition } from 'anims.js';


export class ResetPasswordConfirm extends Component {
  static contextType = AppContext;
  
  constructor(props) {
    super(props);
    
    this.state = {
      success: false,
    }
  }
  
  onSuccess = (resp) => {
    this.setState({success: true});
  }
  
  render() {
    return (
      <PageTransition pageKey='ResetPasswordConfirm'>
        <div className='default-page-container'>
          <div className='page-title'>Reset your password</div>
          <div className='page-content'>
            {this.state.success ? (
              <>
                <p>Your password has been updated. You can now use it to log in.</p>
                
                <motion.div className='default-btn' whileHover={{scale: 1.05}} whileTap={{scale: 0.95}} onClick={(e) => {
                    navigate('/login')
                  }}>Login</motion.div>
              </>
            ) : (
              <>
                <div className='form-header'>
                  Enter your new password
                </div>
                
                <Form submitUrl='/api/users/password/reset/confirm/' onSuccess={ this.onSuccess } hiddenData={{uid: this.props.uid, token: this.props.token}}>
                  <Text id='new_password1' placeholder='New password' type='password' maxLength='128'/>
                  <Text id='new_password2' placeholder='Confirm new password' type='password' maxLength='128'/>
                </Form>
                
              </>
            )}
          </div>
        </div>
      </PageTransition>
    );
  }
}



