import React, { Component } from 'react';
import { navigate } from '@reach/router';
import { motion } from 'framer-motion';

import { AppContext } from 'context.js';
import { PageTransition } from 'anims.js';
import { post } from 'utils/requests.js';


export class ChangeEmailVerify extends Component {
  static contextType = AppContext;
  
  constructor(props) {
    super(props);
    
    this.state = {
      success: null,
      newEmail: null,
      error: null,
    }
  }
  
  componentDidMount() {
    if (!this.context.authed) {
      return
    }
    
    post('/api/users/email/change/confirm/', {payload: this.props.payload})
    .then(resp => {
      this.setState({success: true, newEmail: resp.email});
    })
    .catch(resp => {
      this.setState({success: false, error: resp.non_field_errors[0]});
    })
  }
  
  render() {
    let out;
    
    if (!this.context.authed) {
      out = <>
        <p>You must be logged in to your account before you can verify your email address.</p>
        <motion.div className='default-btn' whileHover={{scale: 1.05}} whileTap={{scale: 0.95}} onClick={(e) => {
            navigate('/login')
          }}>Click here to log in</motion.div>
      </>
    }
    else if (this.state.success == null) {
      out = <p>Loading...</p>
    }
    else if (this.state.success) {
      out = <>
        <p>Your email has been successfully updated to {this.state.newEmail}.</p>
        <motion.div className='default-btn' whileHover={{scale: 1.05}} whileTap={{scale: 0.95}} onClick={(e) => {
            navigate('/settings')
          }}>Return to settings</motion.div>
      </>
    }
    else {
      out = <p>{this.state.error}</p>
    }
    
    return (
      <PageTransition pageKey='ChangeEmailVerify'>
        <div className='default-page-container'>
          <div className='page-title'>Email verification</div>
          <div className='page-content'>{out}</div>
        </div>
      </PageTransition>
    )
  }
}
