import React, { useContext } from 'react';

import { FormContext } from 'components/Form.js';

import './Fields.scss';


export const Text = ({id, type, label, placeholder, autoFocus, maxLength}) => {
	const form = useContext(FormContext);
	
	const onChange = (e) => {
		e.preventDefault();
		form.setValue(e.target.name, e.target.value);
	}
	
	const onFocus = (e) => {
		form.removeFieldError(id);
	}
	
	const onKeyPress = (e) => {
		if (e.key === 'Enter' || e.keyCode === 13) {
			e.preventDefault();
			form.handleSubmit(e);
		}
	}
	
	const error = form.fieldErrors[id];
	
	return (
		<div className='form-field'>
			<input 
				id={ id }
				type={ type || 'text' }
				name={ id }
				onChange={ onChange }
				onFocus={ onFocus }
				onKeyPress={ onKeyPress }
				placeholder={ placeholder || null }
				autoFocus={autoFocus ? '1' : null}
				maxLength={maxLength || null}
			/>
			{ error ? (
				<div className='field-error'>{ error }</div>
			) : null }
		</div>
	);
}
