import React, { Component } from 'react';
import { Router, navigate, globalHistory } from '@reach/router';

import { Login } from 'pages/Login.js'
import { Register } from 'pages/Register.js'
import { ShooteyGuyLeaderboards } from 'pages/games/shootey-guy/ShooteyGuyLeaderboards.js'
import { ShooteyGuyAdminPanel } from 'pages/games/shootey-guy/ShooteyGuyAdminPanel.js'
import { Stocks } from 'pages/Stocks.js'
import { Nav } from 'components/Nav.js'
import { Home } from 'pages/Home.js'
import { Games } from 'pages/games/Games.js'
import { ChangeEmail } from 'pages/user-management/ChangeEmail.js'
import { ChangeEmailVerify } from 'pages/user-management/ChangeEmailVerify.js'
import { ChangePassword } from 'pages/user-management/ChangePassword.js'
import { RecoverUsername } from 'pages/user-management/RecoverUsername.js'
import { ResetPassword } from 'pages/user-management/ResetPassword.js'
import { ResetPasswordConfirm } from 'pages/user-management/ResetPasswordConfirm.js'
import { UserSettingsMain } from 'pages/user-management/UserSettingsMain.js'
import { AppContext } from 'context.js'
import { get } from 'utils/requests.js'

import 'css/default.scss';


export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authed: null,
      username: null,
      staff: null,
      path: window.location.pathname,
    }
  }
  
  componentDidMount() {
    document.addEventListener('keydown', this.eatEnterKeypress, true);
    this.historyUnsubscribe = globalHistory.listen((props) => {
      this.setState({path: props.location.pathname})
    })
    this.setAuthed();
  }
  
  componentWillUnmount() {
    document.removeEventListener('keydown', this.eatEnterKeypress, true);
    this.historyUnsubscribe();
  }
  
  // Basically just stops gross console errors from plugins like LastPass
  eatEnterKeypress(e) {
    if (e.key === 'Enter' || e.keyCode === 13) e.stopPropagation();
  }
  
  setAuthed = () => {
    get('/api/users/authed/')
    .then(res => {
      this.setState({
        authed: res.authed,
        username: res.username,
        staff: res.staff,
      })
    })
    .catch(err => {
      this.setState({
        authed: false,
        username: null,
        staff: false,
      })
      console.log('get authed error: ' + err);
    })
  }
  
  login = () => {
    navigate('/');
    this.setAuthed();
  }
  
  logout = () => {
    this.setState({
      authed: false,
      username: null,
      staff: false,
    })
  }
  
  render() {
    let authed = this.state.authed;
    if (authed == null) return null;
    
    let username = this.state.username;
    let staff = this.state.staff;
    let appContext = { authed, username, staff, login: this.login, logout: this.logout }
    
    return (
      <AppContext.Provider value={appContext}>
        <div className='app'>
          <Nav path={this.state.path}></Nav>
          <div className='app-content'>
            <Router primary={false}>
              <Home path='/' />
              <Games path='/games' />
              <Stocks path='/stonks' />
              <ChangeEmailVerify path='/email/verify/:payload' />
              
              {authed ? <UserSettingsMain path='/settings' /> : null}
              {authed ? <ChangeEmail path='/settings/change-email' /> : null}
              {authed ? <ChangePassword path='/settings/change-password' /> : null}
              
              {!authed ? <Login path='/login' /> : null}
              {!authed ? <Register path='/register' /> : null}
              {!authed ? <ResetPassword path='/reset-password' /> : null}
              {!authed ? <ResetPasswordConfirm path='/reset-password/:uid/:token' /> : null}
              {!authed ? <RecoverUsername path='/recover-username' /> : null}
              
              {staff ? <ShooteyGuyAdminPanel path='/games/shootey_guy/panel' /> : null}
              {staff ? <ShooteyGuyLeaderboards path='/games/shootey_guy/leaderboards' /> : null}
              
              <Home default />
            </Router>
          </div>
        </div>
      </AppContext.Provider>
    );
  }
}
