import React from 'react';

import './Spinner.scss';

import {ReactComponent as PacmanIcon} from 'img/pacman.svg';


export const Spinner = ({spinnerType}) => {
	
	if (spinnerType === 'pacman') {
		return <PacmanIcon className='pacman-icon btn-loader-icon'/>
	}
	else if (spinnerType === 'ring') {
		return <div className='lds-ring btn-loader-icon'><div></div><div></div><div></div><div></div></div>
	}
	
	return <div className='btn-loader-icon'>Loading...</div>
}
