import React, { useState } from 'react';
import { motion } from 'framer-motion';

import { post } from 'utils/requests.js';
import { Spinner } from 'components/Spinner.js';

import './Form.scss';

export const FormContext = React.createContext({});


export const Form = ({children, submitUrl, onSuccess, hiddenData}) => {
	const [formData, setformData] = useState({});
	const [fieldErrors, setFieldErrors] = useState({});
	const [nonFieldErrors, setNonFieldErrors] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	
	const setValue = (name, value) => {
		setformData(prevState => ({ ...prevState, [name]: value }))
	}
	
	const removeFieldError = (id) => {
		delete fieldErrors[id];
		setFieldErrors(prevState => ({ ...fieldErrors }));
	}
	
	const handleSubmit = (e) => {
		// console.log(formData);
		setIsLoading(true)
		setNonFieldErrors(null)
		
		let postData = {...formData};
		
		if (hiddenData) {
			postData = {...postData, ...hiddenData}
		}
		
		post(submitUrl, postData)
		.then(resp => {
			if (onSuccess) onSuccess(resp);
		})
		.catch(errors => {
			// console.log(errors);
			setNonFieldErrors(errors.non_field_errors);
			setFieldErrors(prevState => ({ ...errors }));
			setIsLoading(false)
		})
	}
	
	// pass anything that the children fields need via context
	const form = {
		setValue,
		fieldErrors,
		removeFieldError,
		handleSubmit,
	};
	
	return (
		<FormContext.Provider value={form}>
			<div className='form-container'>
				<form autoComplete='off'>
					{ children }
				</form>
				{ nonFieldErrors ? <div className='non-field-error'>{ nonFieldErrors }</div> : null }
				{ isLoading ? 
					<Spinner spinnerType='pacman' />
				:
					<motion.div className='default-btn' whileHover={{scale: 1.05}} whileTap={{scale: 0.95}} onClick={(e) => {
						setTimeout(() => handleSubmit(e), 50)
					}}>Submit</motion.div>
				}
			</div>
		</FormContext.Provider>
	);
}
