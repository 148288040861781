import React, { Component } from 'react';
import { motion } from 'framer-motion';
import { navigate } from '@reach/router';

import { AppContext } from 'context.js';
import { Form } from 'components/Form.js';
import { Text } from 'components/fields/Text.js'
import { PageTransition } from 'anims.js';


export class ChangeEmail extends Component {
  static contextType = AppContext;
  
  constructor(props) {
    super(props);
    
    this.state = {
      success: false,
      pendingEmail: null,
    }
  }
  
  onSuccess = (resp) => {
    this.setState({success: true, pendingEmail: resp.email});
  }
  
  render() {
    return (
      <PageTransition pageKey='ChangeEmail'>
        <div className='default-page-container'>
          <div className='page-title'>Change your email</div>
          <div className='page-content'>
            {this.state.success ? (
              <>
                <p>A verification email has been sent to {this.state.pendingEmail}.</p>
                <p>Please click the link in the email to complete the update process.</p>
                <p>The link will self destruct in 15 minutes! Go go go go!</p>
                
                <motion.div className='default-btn' whileHover={{scale: 1.05}} whileTap={{scale: 0.95}} onClick={(e) => {
                    navigate('/settings')
                  }}>Return to settings</motion.div>
              </>
            ) : (
              <>
                <div className='form-header'>
                  <p>Enter the email address you would like to link to your account.</p>
                  <p>After submitting, we will send you a verification email to confirm you actually own the email address.</p>
                </div>
                
                <Form submitUrl='/api/users/email/change/' onSuccess={ this.onSuccess }>
                  <Text id='email' placeholder='New email address' maxLength='128' autoFocus/>
                </Form>
                
                <motion.div className='cancel-btn' whileHover={{scale: 1.05}} whileTap={{scale: 0.95}} onClick={(e) => {
                    navigate('/settings')
                  }}>Cancel</motion.div>
              </>
            )}
          </div>
        </div>
      </PageTransition>
    );
  }
}
