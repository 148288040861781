import React, { Component } from 'react';
import { Link } from '@reach/router';

import { AppContext } from 'context.js';
import { PageTransition } from 'anims.js';
import { Form } from 'components/Form.js';
import { Text } from 'components/fields/Text.js'


export class Login extends Component {
  static contextType = AppContext;
  
  constructor(props) {
      super(props);
      
      this.state = {}
  }
  
  onSuccess = () => {
      this.context.login();
  }
  
  render() {
    return (
      <PageTransition pageKey='Login'>
        <div className='default-page-container'>
          <div className='page-title'>Login</div>
          <div className='page-content'>
            <Form submitUrl='/api/users/login/' onSuccess={ this.onSuccess }>
              <Text id='username' placeholder='Username' maxLength='20' autoFocus />
              <Text id='password' placeholder='Password' type='password' maxLength='128'/>
            </Form>
            
            <div className='form-footer'>
              <p>Don't have an account yet? <Link to='/register'>Sign up here.</Link></p>
              <p>Forgot your username? <Link to='/recover-username'>Click here to recover it.</Link></p>
              <p>Forgot your password? <Link to='/reset-password'>Click here to reset it.</Link></p>
            </div>
          </div>
        </div>
      </PageTransition>
    );
  }
}
