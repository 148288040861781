import React, { Component } from 'react';
import { Link, navigate } from '@reach/router';
import { motion, AnimatePresence } from 'framer-motion';

import { AppContext } from 'context.js';
import { get } from 'utils/requests.js';

import './Nav.scss';

import {ReactComponent as HouseIcon} from 'img/house.svg';
import {ReactComponent as ControllerIcon} from 'img/controller.svg';
import {ReactComponent as StocksIcon} from 'img/stocks.svg';
import {ReactComponent as DotsIcon} from 'img/dots.svg';
import {ReactComponent as XIcon} from 'img/x.svg';


export class Nav extends Component {
  static contextType = AppContext;
  
  constructor(props) {
    super(props);
    this.state = {
      moreMenuOpen: false,
    }
    
    this.mobileNavVariants = {
      moreMenuOpen: {
        height: '180px', // Should equal the default height of the mobile-nav PLUS the height of the mobile-more-menu
        transition: {duration: 0.2},
      },
      moreMenuClosed: {
        height: '80px', // Should equal the default height of the mobile-nav
        transition: {duration: 0.2, delay: 0.2},
      },
    }
    
    this.moreMenuParentVariants = {
      open: {
        transition: {
          delayChildren: 0.15,
          staggerChildren: 0.15,
        },
      },
      closed: {},
    }
    
    this.moreMenuChildVariants = {
      open: {opacity: 1},
      closed: {opacity: 0},
    }
  }
  
  doLogout = (e) => {
    e.preventDefault();
    get('/api/users/logout/')
    .then(resp => {
      this.context.logout();
      navigate('/');
    })
    .catch(err => {
      console.log('logout error: ' + err);
    })
  }
  
  activeClass = (path) => {
    if (this.props.path === path) {
      return 'active';
    }
    
    // Special case for settings pages
    if (path === '/settings' && this.props.path.startsWith('/settings/')) {
      return 'active';
    }
    
    return '';
  }
  
  toggleMoreMenu = () => {
    this.setState({moreMenuOpen: !this.state.moreMenuOpen})
  }
  
  render() {
    // Top nav bar for widths > mobile
    let desktopNav = <>
      <div className='nav'>
        <Link to='/'><div className={'nav-btn ' + this.activeClass('/')}>Home</div></Link>
        <Link to='/games'><div className={'nav-btn ' + this.activeClass('/games')}>Games</div></Link>
        <Link to='/stonks'><div className={'nav-btn ' + this.activeClass('/stonks')}>Stonks</div></Link>
        {this.context.authed ? (
          <>
            {this.context.staff ? <Link to='/games/shootey_guy/panel'><div className={'nav-btn ' + this.activeClass('/games/shootey_guy/panel')}>Shootey Guy Panel</div></Link> : null}
            <div className='spacer'></div>
            <Link to='/settings'><div className={'nav-btn ' + this.activeClass('/settings')}>Settings</div></Link>
          </>
        ) : (
          <>
            <div className='spacer'></div>
            <Link to='/login'><div className={'nav-btn ' + this.activeClass('/login')}>Login</div></Link>
            <Link to='/register'><div className={'nav-btn ' + this.activeClass('/register')}>Register</div></Link>
          </>
        )}
      </div>
    </>
        
    // Bottom tap menu for widths <= mobile. Contains the 3 most important nav links + the "more" menu toggle button
    let mobileNavMainBar = <>
      <motion.div className='mobile-nav' initial={false} variants={this.mobileNavVariants} animate={this.state.moreMenuOpen ? 'moreMenuOpen' : 'moreMenuClosed'}>
        <Link to='/'>
          <motion.div className={'nav-btn ' + this.activeClass('/')} whileTap={{scale: 0.9}}>
            <HouseIcon className='mobile-nav-icon'/>
            <div className='mobile-nav-label'>Home</div>
          </motion.div>
        </Link>
        
        <Link to='/games'>
          <motion.div className={'nav-btn ' + this.activeClass('/games')} whileTap={{scale: 0.9}}>
            <ControllerIcon className='mobile-nav-icon'/>
            <div className='mobile-nav-label'>Games</div>
          </motion.div>
        </Link>
      
        <Link to='/stonks'>
          <motion.div className={'nav-btn ' + this.activeClass('/stonks')} whileTap={{scale: 0.9}}>
            <StocksIcon className='mobile-nav-icon'/>
            <div className='mobile-nav-label'>STONKS</div>
          </motion.div>
        </Link>
      
        <motion.div className={'nav-btn '} onClick={() => this.toggleMoreMenu()} whileTap={{scale: 0.9}}>
          {this.state.moreMenuOpen ? <XIcon className='mobile-nav-icon'/> : <DotsIcon className='mobile-nav-icon'/>}
          <div className='mobile-nav-label'>More</div>
        </motion.div>
      </motion.div>
    </>
        
    // "More" menu that can be toggled from the mobile tap bar
    let moreMenuOutput = null;
    if (this.state.moreMenuOpen) {
      moreMenuOutput = <>
        <motion.div className='mobile-more-menu' variants={this.moreMenuParentVariants} initial='closed' animate='open' exit='closed'>
          {this.context.authed ? (
            <>
              <Link to='/settings'>
                <motion.div className={'more-menu-item ' + this.activeClass('/settings')} variants={this.moreMenuChildVariants} whileTap={{scale: 0.9}} onClick={() => {
                  this.toggleMoreMenu();
                }}>Settings</motion.div>
              </Link>
              
              <motion.div className='more-menu-item' variants={this.moreMenuChildVariants} whileTap={{scale: 0.9}} onClick={(e) => {
                this.doLogout(e);
                this.toggleMoreMenu();
              }}>Logout</motion.div>
            </>
          ) : (
            <>
              <Link to='/login'>
                <motion.div className={'more-menu-item ' + this.activeClass('/login')} variants={this.moreMenuChildVariants} whileTap={{scale: 0.9}} onClick={() => {
                  this.toggleMoreMenu();
                }}>Login</motion.div>
              </Link>
              
              <Link to='/register'>
                <motion.div className={'more-menu-item ' + this.activeClass('/register')} variants={this.moreMenuChildVariants} whileTap={{scale: 0.9}} onClick={() => {
                  this.toggleMoreMenu();
                }}>Register</motion.div>
              </Link>
            </>
          )}
        </motion.div>
      </>
    }
        
    return <>
      {desktopNav}
      {mobileNavMainBar}
      <AnimatePresence>{moreMenuOutput}</AnimatePresence>
    </>
  }
}
