import React from 'react';
import { motion } from 'framer-motion';

export const anims = {
	pageContentHidden: { opacity: 0 },
	pageContentVisible: { opacity: 1 },
};

export const PageTransition = ({ children, pageKey }) => {
	return (
		<motion.div
		  key={pageKey}
		  variants={anims}
		  initial='pageContentHidden'
		  animate='pageContentVisible'
		>
			{children}
		</motion.div>
	);
};
