import React, { Component } from 'react';
import { navigate } from '@reach/router'

import { AppContext } from 'context.js'
import { post, get } from 'utils/requests.js'
import { PageTransition } from 'anims.js';

import './ShooteyGuyAdminPanel.scss';


export class ShooteyGuyAdminPanel extends Component {
  static contextType = AppContext;
  
  constructor(props) {
    super(props);
    this.state = {
      pollInterval: null,
      getError: null,
      postError: null,
      processorRunning: null,
      socketRunning: null,
      players: [],
    }
  }
  
  componentDidMount() {
    if (!this.context.staff) {
      navigate('/');
      return null;
    }
    this.getData();
    var pollInterval = setInterval(() => {
      this.getData();
    }, 1000);
    this.setState({pollInterval: pollInterval});
  }
  
  componentWillUnmount() {
    clearInterval(this.state.pollInterval);
  }
  
  getData = () => {
    get('/api/shootey_guy-panel/')
    .then(resp => {
      this.setState({
        getError: null,
        processorRunning: resp.processor_running,
        socketRunning: resp.socket_running,
        players: resp.players,
      });
    })
    .catch(err => {
      this.setState({
        getError: 'GET error. The api is probably down.',
      });
    })
  }
  
  postOption = (option) => {
    post('/api/shootey_guy-panel/', {
      option: option,
    })
    .then(resp => {
      // console.log('post resp: ', resp);
    })
    .catch(err => {
      console.log('post err: ', err);
    })
  }
  
  render() {
    let panelContent = null;
    if (this.state.getError) {
      panelContent = <div className='panel-content'>
        {this.state.getError}
      </div>;
    }
    else {
      let processorRunningDisplay = <div>Processor running: {this.state.processorRunning ? 'Yes' : 'No'}</div>
      let socketRunningDisplay = <div>Socket running: {this.state.socketRunning ? 'Yes' : 'No'}</div>
      
      let tableOutput = null;
      if (this.state.processorRunning && this.state.socketRunning) {
        let players = this.state.players || {};
        let playerCount = Object.keys(players).length;
        let playersRows = Object.keys(players).map(player_id => (
          <tr key={player_id}>
            <td>{players[player_id]['id']}</td>
            <td>{players[player_id]['username']}</td>
            <td>{players[player_id]['ping']}</td>
            <td>{Math.round(players[player_id]['pos']['x'])}</td>
            <td>{Math.round(players[player_id]['pos']['y'])}</td>
            <td>{Math.round(players[player_id]['level'])}</td>
            <td>{players[player_id]['alive'] ? 'Yes' : 'No'}</td>
          </tr>
        ));
          
        tableOutput = <>
          {/* <motion.button className='default-btn' onClick={() => this.postOption('force_stop')} whileHover={{scale: 1.05}} whileTap={{scale: 0.95}}>Force Stop</motion.button> */}
          
          {this.state.postError ? <div className='error-holder'>{this.state.postError}</div> : null}
          
          <br/><br/>Players online: {playerCount}
          
          <table>
            <tbody>
              <tr>
                <th>Player ID</th>
                <th>Username</th>
                <th>Ping</th>
                <th>x</th>
                <th>y</th>
                <th>Level</th>
                <th>Alive</th>
              </tr>
              {playersRows}
            </tbody>
          </table>
        </>
      }
        
      panelContent = <div className='panel-content'>
        {processorRunningDisplay}
        {socketRunningDisplay}
        {tableOutput}
      </div>;
    }
      
    return (
      <PageTransition pageKey='ShooteyGuyAdminPanel'>
        <div className='panel-container'>
          <div className='page-title'>Shootey Guy Admin Panel</div>
          {panelContent}
        </div>
      </PageTransition>
    );
  }
}
