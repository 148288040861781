import React, { Component } from 'react';
import { Link } from '@reach/router';

import { AppContext } from 'context.js';
import { PageTransition } from 'anims.js';
import { Form } from 'components/Form.js';
import { Text } from 'components/fields/Text.js'


export class Register extends Component {
  static contextType = AppContext;
  
  constructor(props) {
    super(props);
    
    this.state = {
      success: false
    }
  }
  
  onSuccess = () => {
    this.context.login();
  }
  
  render() {
    return (
      <PageTransition pageKey='Register'>
        <div className='default-page-container'>
          <div className='page-title'>Register a new account</div>
          <div className='page-content'>
            {this.state.success ? (
              <p>Account created! Please check your email for an activation link.</p>
            ) : (
              <>
                <Form submitUrl='/api/users/create/' onSuccess={ this.onSuccess }>
                  <Text id='username' placeholder='Username' maxLength='20' autoFocus/>
                  <Text id='password1' placeholder='Password' type='password' maxLength='128'/>
                  <Text id='password2' placeholder='Confirm Password' type='password' maxLength='128'/>
                </Form>
              
                <div className='form-footer'>
                  <p>Have an account already? <Link to='/login'>Log in here.</Link></p>
                </div>
              </>
            )}
          </div>
        </div>
      </PageTransition>
    );
  }
}
