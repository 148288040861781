import React, { Component } from 'react';

import { AppContext } from 'context.js';
import { Form } from 'components/Form.js';
import { Text } from 'components/fields/Text.js'
import { PageTransition } from 'anims.js';


export class RecoverUsername extends Component {
  static contextType = AppContext;
  
  constructor(props) {
    super(props);
    
    this.state = {
      success: false,
      email: null,
    }
  }
  
  onSuccess = (resp) => {
    this.setState({success: true, email: resp.email});
  }
  
  render() {
    return (
      <PageTransition pageKey='RecoverUsername'>
        <div className='default-page-container'>
          <div className='page-title'>Username recovery</div>
          <div className='page-content'>
            {this.state.success ? (
              <p>If {this.state.email} is associated with an account, we will send an email to it containing your username.</p>
            ) : (
              <>
                <div className='form-header'>
                  Enter the email address linked to your account.
                </div>
                
                <Form submitUrl='/api/users/username/recover/' onSuccess={ this.onSuccess }>
                  <Text id='email' placeholder='Your email address' maxLength='128' autoFocus/>
                </Form>
                
                <div className='form-footer'>
                  <p>Note: if you did not previously associate an email with your account, you will not be able to recover your username here.</p>
                </div>
              </>
            )}
          </div>
        </div>
      </PageTransition>
    );
  }
}
